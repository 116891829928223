import React from 'react'
import Styles from "../styles/components/ColumnLayout.module.scss"

export const ColumnLayout = ( {
    children
}: any ) =>
    <div className={ Styles.container }>
        <div className={ Styles.column_container }>
            <div className={ Styles.left_column }>
            </div>
            <div className={ Styles.main_container }>
                { children }
            </div>
            <div className={ Styles.right }>
            </div>
        </div>
    </div>
