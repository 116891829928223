import React from 'react'
import { kebabCase } from 'lodash'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { Layout } from '../../components/Layout'
import { ColumnLayout } from "../../components/ColumnLayout"
import Styles from "../../styles/atoms/Tag.module.scss"

const TagsPage = ( {
    data: {
        siteData: {
            siteMetadata: { title },
        },
        allTags: {
            edges
        },
        tagGroups: {
            group
        }
    }
}: any ) => {

    const allTagList = edges.map( (edge: any) => edge.node.frontmatter )

    return (
        <Layout>
            <ColumnLayout>
                <section>
                    <Helmet title={ `Tags | ${ title }` } />
                    <div>
                        <div>
                            <div>
                                <h1>タグ</h1>
                                <ul>
                                    { group.map( (annotatedTag: any) => <li className={ Styles.tag } key={ annotatedTag.fieldValue }>
                                        <Link to={ `/tag/${ kebabCase( allTagList.find( (tag: any) => tag.name === annotatedTag.fieldValue ).EnglishName ) }/` }>
                                            { annotatedTag.fieldValue } ({ annotatedTag.totalCount })
                                            { console.log( "tag", annotatedTag ) }
                                        </Link>
                                    </li>
                                    ) }
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </ColumnLayout>
        </Layout>
    );
}
export default TagsPage

export const tagPageQuery = graphql`
    query allTagListAndPostList{
        siteData:  site {
            siteMetadata {
                title
            }
        }
        allTags:
            allMarkdownRemark(
                filter: { fields: { slug: {regex: "/tags.+/"} } }
            )  {
                edges{
                    node {
                        frontmatter {
                            name
                            EnglishName
                        }
                    }
                }
            }
        tagGroups:
            allMarkdownRemark{
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
               }
            }
        }  
`
